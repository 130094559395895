/* You can add global styles to this file, and also import other style files */

// Classes abaixo são implicitamente colocadas pelo Angular
//.ng-invalid => conteúdo inválido no elemento
//.ng-untouched => elemento não foi 'tocado'

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css");

/* --------------- */
/* Início do reset */
/* --------------- */
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
// html, body, div, span, applet, object, iframe,
// h1, h2, h3, h4, h5, h6, p, blockquote, pre,
// a, abbr, acronym, address, big, cite, code,
// del, dfn, em, img, ins, kbd, q, s, samp,
// small, strike, strong, sub, sup, tt, var,
// b, u, i, center,
// dl, dt, dd, ol, ul, li,
// fieldset, form, label, legend,
// table, caption, tbody, tfoot, thead, tr, th, td,
// article, aside, canvas, details, embed,
// figure, figcaption, footer, header, hgroup,
// menu, nav, output, ruby, section, summary,
// time, mark, audio, video {
//   margin: 0;
//   padding: 0;
//   border: 0;
//   font-size: 100%;
//   font: inherit;
//   vertical-align: baseline;
// }
// /* HTML5 display-role reset for older browsers */
// article, aside, details, figcaption, figure,
// footer, header, hgroup, menu, nav, section {
//   display: block;
// }
// body {
//   line-height: 1;
// }
// ol, ul {
//   list-style: none;
// }
// blockquote, q {
//   quotes: none;
// }
// blockquote:before, blockquote:after,
// q:before, q:after {
//   content: '';
//   content: none;
// }
// table {
//   border-collapse: collapse;
//   border-spacing: 0;
// }
/* ------------ */
/* Fim do reset */
/* ------------ */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.red {
    color: #e35e6b;
}

.v-middle {
    vertical-align: middle;
}

.msg-success {
    background-color: #28a745;
    color: #fff;
}

.msg-error {
    background-color: #e35e6b;
    color: #fff;
}

.msg-validador {
  margin: 0px 0px 0px 0px;
  border: 0px solid red;
  padding-top: 0px;
  font-size: 11px;
  color: red;
}

// .ng-invalid.ng-touched:not(form):not(mat-form-field) {
//   border: 2px solid red;
// }

.button-row {
  display: table-cell;
  width: 490px;
}

.button-row .mat-button-base {
  margin: 8px 8px 8px 0;
}

.textoDescricao {
  // min-width: 145px;
  // max-width: 600px;
  text-align: justify;
  // border-style: solid;
}
